<template>
  <div class="admin-container" v-if="data">
    <!-- <div class="admin-container"> -->
    <!-- <h1 class="col-secondary-1">Video živě</h1> -->
    <h1 class="col-secondary-1">Připravujeme</h1>

    <!-- <v-btn class="mt-4" small @click="toVideo">Video živě</v-btn> -->

    <!-- <div
      @click="toVideo"
      style="
        height: 150px;
        border: 1px solid black;
        display: flex;
        align-items: center;
        cursor: pointer;
      "
    >
      Video
    </div> -->
    <!-- 
    <div class="fp-Video">
      <div id="play" class="display"></div>
    </div>
    <button id="playBtn" @click="playClick">PLAY</button> -->

    <!-- <video
      src="rtsp://user:VkW5*Tb4@89.24.17.73:9761/Streaming/Channels/1O1/?transportmode=unicast"
    ></video> -->
    <!-- <video
      src="https://user:VkW5*Tb4@ignis.effectiveinvention.cz:9351/ISAPI/Streaming/channels/102/picture"
    ></video> -->
    <!-- <embed :src="data" width="500" height="360" /> -->
  </div>
</template>

<script>
import authHandler from '../api/authHandler';
import bus from '../plugins/bus';
import buildingService from '../api/buildingService';
import Hls from 'hls.js';

var SESSION_STATUS;
var STREAM_STATUS;
var session;
var PRELOADER_URL =
  'https://github.com/flashphoner/flashphoner_client/raw/wcs_api-2.0/examples/demo/dependencies/media/preloader.mp4';
var Browser;

export default {
  data() {
    return {
      data: true,
      Browser: false,
      session: null,
    };
  },
  async created() {},
  async mounted() {
    if (document) {
      // let scriptEl = document.createElement("script");
      // scriptEl.setAttribute(
      //   "src",
      //   "https://flashphoner.com/downloads/builds/flashphoner_client/wcs_api-2.0/current/flashphoner.js"
      // );
      // document.head.appendChild(scriptEl);

      try {
        //   await this.$store.dispatch("getCurrentUser");
        if (this.getSelectedBuilding() != null) {
          // await this.getVideoLink();

          // if (window.Flashphoner) {
          //   SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
          //   STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;

          //   this.Browser = /^((?!chrome|android).)*safari/i.test(
          //     navigator.userAgent
          //   );

          //   this.init_api();
          // }
        } else {
          this.$router.push({ name: 'Dashboard' });
          bus.$emit('snackbar', {
            text: 'Není vybrána žádná stavba.',
            color: 'error',
          });
        }
      } catch (error) {
        console.error(error);
        if (!authHandler(error)) this.$router.push({ name: 'Login' });
      }
    }
  },
  methods: {
    // RTSP
    // init_api() {
    //   window.Flashphoner.init({});
    //   //Connect to WCS server over websockets
    //   this.session = Flashphoner.createSession({
    //     urlServer: 'wss://demo.flashphoner.com', //specify the address of your WCS
    //     // urlServer: "ws://89.24.17.73", //specify the address of your WCS
    //   }).on(SESSION_STATUS.ESTABLISHED, function (session) {
    //     console.log('ESTABLISHED');
    //   });
    // },
    playClick() {
      if (this.Browser) {
        window.Flashphoner.playFirstVideo(
          document.getElementById('play'),
          true,
          PRELOADER_URL
        ).then(function () {
          this.playStream();
        });
      } else {
        this.playStream();
      }
    },
    playStream() {
      console.debug('Play the stream');
      this.session
        .createStream({
          name: 'rtsp://user:VkW5*Tb4@89.24.17.73:9761/Streaming/Channels/1O1/?transportmode=unicast', //specify the RTSP stream address
          display: document.getElementById('play'),
        })
        .play();
    },
    // /RTSP
    toVideo() {
      window.open(
        // this.data,
        // "http://admin:heslo12345@89.24.17.73:9999/ISAPI/Streaming/channels/102/httpPreview",
        this.data,
        'winname',
        'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350'
      );
    },
    toMessages() {
      if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.data.id },
        });
      }
      if (['PROJECT_MANAGER'].includes(this.user.role)) {
        this.$router.push({
          name: 'MessagesDetail',
          params: { id: this.getSelectedBuilding().investor.id },
        });
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    async getVideoLink() {
      try {
        bus.$emit('processing');
        const data = await buildingService.onlineLink();
        bus.$emit('processing', false);
        this.data = data;
        // setTimeout(() => {
        //   this.data = data;
        //   console.debug(this.data);
        //   let hls = new Hls();
        //   let stream = this.data;
        //   let video = this.$refs["liveVideo"];
        //   hls.loadSource(stream);
        //   hls.attachMedia(video);
        //   hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //     video.play();
        //   });
        // }, 1000);
      } catch (error) {
        bus.$emit('processing', false);
      }
    },
  },
  computed: {
    phone() {
      return {
        backgroundImage: `url(${require('@/assets/phone.svg')} !important`,
      };
    },
    email() {
      return {
        backgroundImage: `url(${require('@/assets/email.svg')} !important`,
      };
    },
    www() {
      return {
        backgroundImage: `url(${require('@/assets/www.svg')} !important`,
      };
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style>
.fp-Video {
  border: 1px double black;
  width: 322px;
  height: 242px;
}
.display {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.display > video,
object {
  width: 100%;
  height: 100%;
}
</style>
